<template lang="html">

  <!--

  <div v-bind:id="id" >

    <component class="block" v-if="config.actionid" v-bind:is="'bulmaActions'" v-bind:id="config.actionid"></component>

    <nav class="panel" v-for="(r, index) in config.rows" v-bind:key="r.id">

      <p class="panel-heading" v-if="r.title" style="padding: 0.5em; font-size: 1em;">
        <button v-on:click="tabClick(index, !r.opened)">
          <span class="icon">
            <i v-if="r.opened" class="fas fa-chevron-down"></i>
            <i v-if="!r.opened" class="fas fa-chevron-left"></i>
          </span>
        </button>
        {{ r.title }}
      </p>

      <div class="panel-block" v-if="r.opened">
        <component v-bind:is="r.type" v-bind:id="r.id"></component>
      </div>
      
    </nav>

    <component class="block" v-if="config.actionid" v-bind:is="'bulmaActions'" v-bind:id="config.actionid"></component>

  </div>
  -->

  
  <article class="message" v-bind:id="id" >

    <component class="block" v-if="config.actionid" v-bind:is="'bulmaActions'" v-bind:id="config.actionid"></component>

    <div v-for="(r, index) in config.rows" v-bind:key="r.id" v-bind:class="{'block': index==(config.rows.length-1) }" >

      <div class="message-header" v-if="r.title">
        <p>{{ r.title }}</p>

        <button v-on:click="tabClick(index, !r.opened)">
          <span class="icon">
            <i v-if="r.opened" class="fas fa-chevron-down"></i>
            <i v-if="!r.opened" class="fas fa-chevron-left"></i>
          </span>
        </button>
      </div>

      <div class="message-body" v-if="r.opened" style="padding: 0.75em; padding-top: 0.5em; " v-bind:style="{'border-left': !r.title?'0px':'', 'padding-top': !r.title?'0px':''}">
        <component v-bind:is="r.type" v-bind:id="r.id"></component>
      </div>

    </div>

      <component class="block" v-if="config.actionid" v-bind:is="'bulmaActions'" v-bind:id="config.actionid"></component>

  </article>

</template>

<script>

export default {
  name: 'bulmaSections',
  components: {
  },
  computed: {
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
  },
  props: {
    id: String,
  },
  data() {
    return {
    }
  },
  mounted: function() {
  },
  async created() {
  },
  methods: {
    tabClick: function(tabindex, opened) {
      var c=this.config;
      c.rows[tabindex].opened=opened;
      var payload={ id:this.id, cfg:{ id:this.id, type:this.type, data:c} }      
      this.$store.commit('SET_CONFIG', payload);
    },
  }
}
</script>

<style scoped>
</style>

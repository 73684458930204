<template lang="html">
  <nav class="pagination is-small" role="navigation" aria-label="pagination" v-bind:id="id" >
    <button class="pagination-previous" v-bind:disabled="!prevPageExist" v-on:click="prevPageExist?$emit('previous'):''">Previous</button>
    <button href="#" class="pagination-next" v-bind:disabled="!nextPageExist" v-on:click="$emit('next')">Next page</button>
    <ul class="pagination-list">
      <li v-if="currentPage>1">
        <button href="#" class="pagination-link" v-if="maxPages>1" aria-label="Goto page 1" v-on:click="$emit('goto',1)">1</button>
      </li>
      <li v-if="prevEllipsesExistBut">
        <button href="#" class="pagination-link" v-on:click="$emit('goto',currentPage-2)">{{ currentPage-2 }}</button>
      </li>
      <li v-if="prevEllipsesExist">
        <span class="pagination-ellipsis">&hellip;</span>
      </li>
      <li>
        <button href="#" class="pagination-link" v-if="currentPage>2" v-on:click="$emit('goto',currentPage-1)">{{ currentPage-1 }}</button>
      </li>
      <li>
        <button class="pagination-link is-current">{{ currentPage }}</button>
      </li>
      <li>
        <button href="#" class="pagination-link" v-if="currentPage<(maxPages-1)" v-on:click="$emit('goto',currentPage+1)">{{ currentPage+1 }}</button>
      </li>
      <li v-if="nextEllipsesExist">
        <span class="pagination-ellipsis">&hellip;</span>
      </li>
      <li v-if="nextEllipsesExistBut">
        <button href="#" class="pagination-link" v-on:click="$emit('goto',currentPage+2)">{{ currentPage+2 }}</button>
      </li>
      <li v-if="currentPage<(maxPages)">
        <button href="#" class="pagination-link" v-if="maxPages>1" v-on:click="$emit('goto',maxPages)">{{ maxPages }}</button>
      </li>
    </ul>
  </nav>
</template>

<script>

export default {
  name: 'bulmaPagination',
  components: {
  },
  computed: {
    prevPageExist: function() {
      return this.currentPage>1;
    },
    prevEllipsesExist: function() {
      return this.currentPage>3 && this.maxPages>4;
    },
    prevEllipsesExistBut: function() {
      return this.currentPage>3 && this.maxPages==4;
    },
    nextEllipsesExist: function() {
      return this.currentPage<(this.maxPages-2) && this.maxPages>4;
    },
    nextEllipsesExistBut: function() {
      return this.currentPage<(this.maxPages-2) && this.maxPages==4;
    },
    nextPageExist: function() {
      return this.currentPage<this.maxPages;
    },
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
  },
  props: {
    id: String,
    currentPage: Number,
    maxPages: Number
  },
  data() {
    return {
    }
  },
  mounted: function() {
  },
  async created() {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>

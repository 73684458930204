export default {
    "initialid": "home",
    processes: [
        {   "id": "default", 
            "verbs" : [
                { "id": "default", "pageid": "home"},
            ]
        },
        {   "id": "leads", 
            "table":"Lead",
            "verbs" : [
                { "id": "default", "pageid": "leads"},
                { "id": "d", "pageid": "lead_detail"},
                { "id": "e", "pageid": "lead_edit"},
                { "id": "n", "pageid": "lead_edit"},
            ]
        },
        {   "id": "customers", 
            "table":"Account",
            "verbs" : [
            { "id": "default", "pageid": "accounts"},
            ]
        },
        {   "id": "projects",
            "table":"Opportunity",
            "verbs" : [
                { "id": "default", "pageid": "projects"},
                { "id": "d", "pageid": "projects_detail"},
                { "id": "e", "pageid": "projects_edit"},
                { "id": "n", "pageid": "projects_edit"},
            ]
        },
        {   "id": "contacts",
            "table":"Contact",
            "verbs" : [
                { "id": "default", "pageid": "contacts"},
                { "id": "d", "pageid": "contacts_detail"},
                { "id": "e", "pageid": "contacts_edit"},
                { "id": "n", "pageid": "contacts_edit"},
            ]
        },
        {   "id": "lab",
            "table":"Account",
            "verbs" : [
                { "id": "default", "pageid": "lab"},
            ]
        },
        {   "id": "buddy",
            "table":"Account",
            "verbs" : [
                { "id": "default", "pageid": "buddy"}
            ]
        },
        {   "id": "lookup_account",
            "table":"Account",
            "issubprocess": true,
            "verbs" : [
                { "id": "default", "pageid": "lookup_account"},
                { "id": "d", "pageid": "contacts_detail"},
                { "id": "e", "pageid": "contacts_edit"},
                { "id": "n", "pageid": "contacts_edit"},
            ]
        },
        {   "id": "tasks", 
            "table":"Task",
            "verbs" : [
                { "id": "default", "pageid": "tasks"},
            ]
        },
        {   "id": "dashboards",
            "table":"Dashboard__c",
            "verbs" : [
                { "id": "default", "pageid": "dashboards"},
                { "id": "d", "pageid": "dashboard_detail"},
            ]
        },
    ],
    pages: [
        {   "id": "home", "initialid": "root" },
        {   "id": "p404", "initialid": "p404" },
        {   "id": "buddy", "initialid": "buddy" },
        {   "id": "tasks", "initialid": "tasks" },
        {   "id": "lab", "initialid": "lab" },
        {   "id": "leads", "initialid": "leads" },
        {   "id": "lead_detail", "initialid": "lead_detail" },
        {   "id": "lead_edit", "initialid": "lead_edit" },
        {   "id": "accounts", "initialid": "accounts" },
        {   "id": "projects", "initialid": "deals" },
        {   "id": "projects_detail", "initialid": "deal_detail" },
        {   "id": "projects_edit", "initialid": "deal_edit" },
        {   "id": "lookup_account", "initialid": "lookup_Architect" },
        {   "id": "dashboards", "initialid": "dashboards_list" },
        {   "id": "dashboard_detail", "initialid": "dashboard" },
    ],
    objects: [
        {   "id": "pippo",
            "data": {
            }
        },
        {   "id": "lab",
            "type":"bulmaLab",
            "data": {
            }
        },
        {   "id": "buddy",
            "type":"bulmaBuddy",
            "data": {
                'root': { choose: [ 
                    { name:'create', state:'root-create'}, 
                    { name:'briefme', state:'root-briefme'}, 
                    { name:'search', state:'root-search'}, 
                    { name:'goto', state:'root-goto'}, 
                    ], 
                },
                'root-goto': { choose: [
                    { name:'last project', state:'root-create'},
                    { name:'my customers', state:'root-create'},
                    ]
                },
                'root-create': { choose: [ 
                    { name:'deal', state:'deal-type'}, 
                    { name:'lead', state:'create-lead'}, 
                    { name:'task', state:'create-task'},
                    ], 
                },
                'deal-type': { choose: [ 
                    { name:'CS', state:'deal-brand', data: {"brand":"cs"} }, 
                    { name:'REGIONAL', state:'deal-brand', data: {"brand":"regional"}}, 
                    { name:'NATIONAL', state:'deal-brand', data: {"brand":"national"}}, 
                    ], 
                },
                'deal-brand': { choose: [ 
                    { name:'FTI', state:'deal-confirm', data: {"brand":"fti"} }, 
                    { name:'LEA', state:'deal-confirm', data: {"brand":"lea"}}, 
                    { name:'BOTH', state:'deal-confirm', data: {"brand":"fti&lea"}}, 
                    ], 
                },
                'root-briefme': { choose: [ 
                    { name:'today meetings', state:'briefme-today'}, 
                    { name:'my performance', state:'briefme-performance'}, 
                    { name:'pipeline', state:'briefme-pipeline'}, 
                    ], 
                },
                'create-lead': { choose: [ 
                    { name:'commercial', state:'lead-confirm'}, 
                    { name:'end user', state:'lead-confirm'}, 
                    { name:'new distributor', state:'lead-confirm'}, 
                    ], 
                },
                'deal-confirm': { choose: [ 
                    { name:'confirm', state:'save'}, 
                    { name:'cancel', state:'cancel'}, 
                    ], 
                },
                'lead-confirm': { choose: [ 
                    { name:'confirm', state:'save'}, 
                    { name:'cancel', state:'cancel'}, 
                    ], 
                }
            }
        },
        {   "id": "dashboards_list",
            "type":"bulmaRows",
            "data": {
                rows: [
                    {
                        "id": "mainbar",
                        "type":"bulmaTabBar",
                    },
                    {
                        "id": "dashboard_test",
                        "type":"bulmaGraph",
                    },
                    {
                        "id": "dashboard_test2",
                        "type":"bulmaGraph",
                    },
                    {
                        "id": "bottombar",
                        "type":"bulmaBottomBar",
                    },
                ]
            },
        },
        {   "id": "dashboard_test",
            "type":"bulmaGraph",
            "data": {
                title: {
                    text: 'Project Amounts'
                },
                tooltip: {},
                xAxis: {
                    data: ['shirt', 'cardigan', 'chiffon', 'pants', 'heels', 'socks']
                },
                yAxis: {},
                series: [
                    {
                    name: 'sales',
                    type: 'bar',
                    data: [5, 20, 36, 10, 10, 20]
                    }
                ]
            }
        },
        {   "id": "dashboard_test2",
            "type":"bulmaGraph",
            "data": {
                title: {
                    text: 'Customer Portfolio',
                    left: 'center'
                  },
                  tooltip: {
                    trigger: 'item'
                  },
                  series: [
                    {
                      name: 'Customer Type',
                      type: 'pie',
                      radius: '50%',
                      data: [
                        { value: 1048, name: 'Search Engine' },
                        { value: 735, name: 'Direct' },
                        { value: 580, name: 'Email' },
                        { value: 484, name: 'Union Ads' },
                        { value: 300, name: 'Video Ads' }
                      ],
                      emphasis: {
                        itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                      }
                    }
                  ]
            }
        },        {   "id": "root",
            "notes": "TODO da sistemare la homepage",
            "type":"bulmaRows",
            "data": {
                rows: [
                    {
                        "id": "mainbar",
                        "type":"bulmaTabBar",
                    },
                    {
                        "id": "home_help",
                        "type":"bulmaHelp",
                    },
                    {
                        "id": "bottombar",
                        "type":"bulmaBottomBar",
                    },
                ]
            },
        },
        {   "id":"home_help", "type":"bulmaHelp",
            "data": {
                "help":[
                    "<h1>Welcome to SalesBuddy!</h1>",
                    "<h2>Use the main menu at the top or the Buddy magic button</h2>",
                    "<ul><li>Leads: a new contact</li><li>Customers: the whole customers database</li><li>Projects: project registration db</li></ul>",
                ]
            }
        },
        {   "id": "lookup_Architect", 
            "type": "bulmaTable", 
            "data": {
                table:"Account",
                title:"Seleziona l'architetto",
                caninsert:true,
                checkbox: true,
                // checkbox: 'multi',
                Id: { "label":"Id", "name":"Id", "fieldname":"Id" },
                // path: {
                //     "create": "/contacts/c/",
                //     "details": "/contacts/d/",
                //     "edit": "/contacts/e/",
                // },
                lists:[
                    { "label":"All Accounts", "name":"all", "default": true, "shared":true,
                        cols:[
                            { "label":"Key", "name":"id", "format": "hidden", "fieldname":"Id" },
                            { "label":"Name", "name":"Name", "fieldname":"Name" },
                            { "name": "Type", "label": "Type", "format":"text", "fieldname":"Type" },
                            { "name": "Industry", "label": "Industry", "format":"text", "fieldname":"Industry" },
                            { "name": "BillingState", "label": "State", "format":"text", "fieldname":"BillingState" },
                            { "name": "BillingCity", "label": "City", "format":"text", "fieldname":"BillingCity" },
                            { "name": "BillingStreet", "label": "Street", "format":"text", "fieldname":"BillingStreet" },
                        ],
                        "filters": [
                            { "fieldname": "Type", "operator": "EQ", "value": "Architect" }
                        ],
                        "order": [
                            { "fieldname": "Name", "operator": "ASC" }
                        ]
                    },
                ],
                cols:[
                    { "label":"Key", "name":"id", "format": "hidden", "fieldname":"id" },
                    { "label":"Name", "name":"name", "format": "link", "linkname": "id", "fieldname":"name"},
                    { "label":"Industry", "name":"industry", "format": "text", "fieldname":"Industry"},
                    { "label":"BillingCity", "name":"city", "format": "text", "fieldname":"BillingCity"},
                    { "label":"BillingState", "name":"state", "format": "text", "fieldname":"BillingState"},
                ],
                addForm: { id:"add_Architect", type:"bulmaFieldSet2" },
            }
        },
        {   "id": "add_Architect", 
            "type": "bulmaFieldSet2", 
            "data": {
                "title":"Aggiungi Architetto",
                "table": "Account",
                dual:true,
                fields:[
                    { fieldname:"Name" },
                    { fieldname:"Industry" },
                    { fieldname:"BillingCity" },
                    { fieldname:"BillingState" },
                ]
            }
        },
        {   "id": "mainbar",
            "type":"bulmaTabBar",
            "data": {
                "tabs": [
                    {
                        "name": "Home",
                        "path": "/",
                        "icon":"home"
                    }, {
                        "name": "Leads",
                        "path": "/leads",
                        "icon":"address-card"
                    }, {
                        "name": "Customers",
                        "path": "/customers",
                        "icon":"file-invoice"
                    }, {
                        "name": "Projects",
                        "path": "/projects",
                        "icon":"pencil-ruler"
                    }, {
                        "name": "Dashboards",
                        "path": "/dashboards",
                        "icon":"chart-pie"
                    }
                ],
                "active_tab": "Home",
            }
        },
        {   "id": "p404",
            "type":"bulmaRows",
            "data": {
                rows: [
                    {
                        "id": "mainbar",
                        "type":"bulmaTabBar",
                    },
                    {
                        "id": "action_p404",
                        "type":"bulmaActions",
                    },
                    {
                        "id": "bottombar",
                        "type":"bulmaBottomBar",
                    },
                ]
            },
        },
        {   "id": "action_p404", 
            "type": "bulmaActions", 
            "data": {
                title:"Page not found!",
                actions:[
                    { name: "back", label: "Back", action:"goto_back", default: true, icon:"chevron_left" },
                    { name: "home", label: "Home", action:"goto_home", default: true, icon:"home" },
                ]
            }
        },
        {   "id": "leads",
            "type":"bulmaRows",
            "data": {
                rows: [
                    {
                        "id": "mainbar",
                        "type":"bulmaTabBar",
                    },
                    {
                        "id": "leads_list",
                        "type":"bulmaTable",
                    },
                    {
                        "id": "bottombar",
                        "type":"bulmaBottomBar",
                    },
                ]
            },
        },
        {   "id": "tasks",
            "type":"bulmaRows",
            "data": {
                rows: [
                    {
                        "id": "mainbar",
                        "type":"bulmaTabBar",
                    },
                    {
                        "id": "tasks_list",
                        "type":"bulmaTable",
                    },
                    {
                        "id": "bottombar",
                        "type":"bulmaBottomBar",
                    },
                ]
            },
        },
        {   "id": "lead_detail",
            "type":"bulmaRows",
            "data": {
                rows: [
                    {
                        "id": "mainbar",
                        "type":"bulmaTabBar",
                    },
                    {
                        "id": "lead_form",
                        "type":"bulmaSubTab",
                    },
                    {
                        "id": "bottombar",
                        "type":"bulmaBottomBar",
                    },
                ]
            },
        },
        {   "id": "lead_edit",
            "type":"bulmaRows",
            "data": {
                rows: [
                    {
                        "id": "mainbar",
                        "type":"bulmaTabBar",
                    },
                    {
                        "id": "lead_form",
                        "type":"bulmaSubTab",
                    },
                    {
                        "id": "bottombar",
                        "type":"bulmaBottomBar",
                    },
                ]
            },
        },
        {   "id": "accounts",
            "type":"bulmaRows",
            "data": {
                rows: [
                    {
                        "id": "mainbar",
                        "type":"bulmaTabBar",
                    },
                    {
                        "id": "accounts_list",
                        "type":"bulmaTable",
                    },
                    {
                        "id": "bottombar",
                        "type":"bulmaBottomBar",
                    },
                ]
            },
        },
        {   "id": "deals",
            "type":"bulmaRows",
            "data": {
                rows: [
                    {
                        "id": "mainbar",
                        "type":"bulmaTabBar",
                    },
                    {
                        "id": "deals_list",
                        "type":"bulmaTable",
                    },
                    {
                        "id": "bottombar",
                        "type":"bulmaBottomBar",
                    },
                ]
            },
        },
        {   "id": "deals_actions", 
            "type": "bulmaActions", 
            "data": {
                actions:[
                    { name: "New", label: "New", action:"new_record", default: true, icon:"plus" },
                    { name: "Search", label: "Search", action:"search_record",  icon:"magnifying-glass" },
                ]
            }
        },
        {   "id": "deals_list", 
            "type": "bulmaTable", 
            "data": {
                "table": "Opportunity",
                "title": "Projects",
                "process": "projects",
                "path": {
                    "create": "/projects/c/",
                    "details": "/projects/d/",
                    "edit": "/projects/e/",
                },
                actions:[
                    { name: "New", label: "New", action:"new_record", default: true, icon:"plus" },
                    { name: "Search", label: "Search", action:"search_record",  icon:"magnifying-glass" },
                ],

                "lists":[
                    {  "label":"My Projects", "name":"mine", "default": true, "shared":true, 
                        "cols":[
                            { "id":"id", "label":"Key", "fieldname":"Id", "format": "hidden", },
                            { "id":"name", "label":"Name", "fieldname":"Name", "format": "link", "link_fieldname": "Id", },
                            { "id":"stage", "label":"Stage", "fieldname":"StageName", "format": "text",},
                            { "id":"city", "label":"City", "fieldname":"City__c",  "format": "text", },
                            { "id":"state", "label":"State", "fieldname":"State__c", "format": "text", },
                        ],
                        "filters": [ 
                            { "fieldname": "id", "operator": "NEQ", "value": "" }
                        ],
                        "order": [
                            { "fieldname": "CreatedDate", "operator": "DESC" }
                        ],
                        "actions": [
                            { "fieldname": "CreatedDate", "operator": "DESC" }
                        ]
                    },
                    {  "label":"All Projects", "name":"all",  "shared":false, 
                        "cols":[
                            { "id":"id", "label":"Key", "fieldname":"Id", "format": "hidden", },
                            { "id":"name", "label":"Name", "fieldname":"Name", "format": "link", "link_fieldname": "Id", },
                            { "id":"stage", "label":"Stage", "fieldname":"StageName", "format": "text",},
                            { "id":"city", "label":"City", "fieldname":"City__c",  "format": "text", },
                            { "id":"state", "label":"State", "fieldname":"State__c", "format": "text", },
                        ],
                        "filters": [ 
                            { "fieldname": "City__c", "operator": "GT", "value": "" }
                        ],
                        "order": [
                            { "fieldname": "LastModifiedDate", "operator": "DESC" }
                        ]
                    },

                ],
                "default_cols":[
                    { "id":"id", "label":"Key", "fieldname":"Id", "format": "hidden", },
                    { "id":"name", "label":"Name", "fieldname":"Name", "format": "link", "link_fieldname": "id", },
                    { "id":"stage", "label":"Stage", "fieldname":"StageName", "format": "text",},
                    { "id":"city", "label":"City", "fieldname":"City__c",  "format": "text", },
                    { "id":"state", "label":"State", "fieldname":"State__c", "format": "text", },
                ],
            }
        },
        {   "id": "deal_detail",
            "type":"bulmaRows",
            "data": {
                rows: [
                    {
                        "id": "mainbar",
                        "type":"bulmaTabBar",
                    },
                    {
                        "id": "deal_form",
                        "type":"bulmaSubTab",
                    },
                    {
                        "id": "bottombar",
                        "type":"bulmaBottomBar",
                    },
                ]
            },
        },
        {   "id": "deal_edit",
            "type":"bulmaRows",
            "data": {
                rows: [
                    {
                        "id": "mainbar",
                        "type":"bulmaTabBar",
                    },
                    {
                        "id": "deal_form",
                        "type":"bulmaSubTab",
                    },
                    {
                        "id": "bottombar",
                        "type":"bulmaBottomBar",
                    },
                ]
            },
        },
        {   "id": "deal_form",
            "type":"bulmaSubTab",
            "data": {
                "tabs": [
                    {
                        "name": "Name", "id": "deal_form_main", "type": "bulmaSections",
                    }, {
                        "name": "Location", "id": "deal_form_location", "type": "bulmaSections",
                    }, {
                        "name": "Players", "id": "deal_form_players", "type": "bulmaSections",
                    }, {
                        "name": "Products", "id": "deal_form_products", "type": "bulmaTable",
                    }, {
                        "name": "Other", "id": "deal_form_products_engagements", "type": "bulmaTable",
                    }, {
                        "name": "Activities", "id": "deal_activities", "type": "bulmaActivities",
                    }
                ],
                "active_tab": "Name",
            }
        },
        {   "id": "deal_form_main", 
            "type": "bulmaSections", 
            "data": {
                title:"Main Data",
                actionid: "action_1",
                rows:[
                    {  title: "Main Data", opened: true,  "id": "deal_fields_main", "type": "bulmaFieldSet2", },
                    {  title: "Other Data", opened: true,  "id": "deal_fields_main2", "type": "bulmaFieldSet2", },
                ],
            }
        },
        {   "id": "deal_fields_main", 
            "type": "bulmaFieldSet2", 
            "data": {
                "table": "Opportunity",
                dual:true,
                fields:[
                    { fieldname:"Name" },
                    { fieldname:"StageName" },
                    { fieldname:"Type" },
                    { fieldname:"OwnerId" },
                    { fieldname:"Sales_Brand__c" },
                    { fieldname:"Material_Type__c" },
                    { fieldname:"Industry__c" },
                    { fieldname:"Bulletin__c" },
                ]
            }
        },
        {   "id": "deal_fields_main2", 
            "type": "bulmaFieldSet2", 
            "data": {
                "table": "Opportunity",
                dual:true,
                fields:[
                    { fieldname:"StageName" },
                    { fieldname:"Probability" },
                    { fieldname:"creation_date" },
                    { fieldname:"amount" },
                ]
            }
        },
        {   "id": "deal_form_location", 
            "type": "bulmaSections", 
            "data": {
                title:"Project Location",
                actionid: "action_1",
                rows:[
                    {  title: "Location Info", opened: true,  "id": "deal_fields_location", "type": "bulmaFieldSet2", },
                    {  title: "Map", opened: true,  "id": "deal_fields_location_map", "type": "bulmaMap", },
                ],
            }
        },
        {   "id": "deal_fields_location", 
            "type": "bulmaFieldSet2", 
            "data": {
                "table": "Opportunity",
                dual:true,
                fields:[
                    { fieldname:"City__c" },
                    { fieldname:"State__c" },
                    { fieldname:"Street__c" },
                    { fieldname:"No_Location__c" },
                ]
            }
        },
        {   "id": "deal_fields_location_map", 
            "type": "bulmaMap", 
            "data": {
                "apiKey": "AIzaSyCz62VKmQTs62wsRSsUg09fEAThkMT3rqE",
                "latitude": 45,
                "longitude": 10
            }
        },
        {   "id": "deal_form_players", 
            "type": "bulmaSections", 
            "data": {
                title:"Players",
                rows:[
                    {  title: "Designers", opened: true,  "id": "deal_fields_architect", "type": "bulmaFieldSet2", },
                    {  opened: true,  "id": "deal_fields_architect_contacts", "type": "bulmaTable", },
                    {  title: "Contractors", opened: true,  "id": "deal_fields_contractor", "type": "bulmaFieldSet2", },
                    {  opened: true,  "id": "deal_fields_contractor_contacts", "type": "bulmaTable", },
                ],
            }
        },
        {   "id": "deal_fields_architect", 
            "type": "bulmaFieldSet2", 
            "data": {
                "table": "Opportunity",
                fields:[
                    { fieldname:"Architect_Designer__c", "list":"architect2" }
                ],
            }
        },
        {   "id": "deal_fields_architect_contacts", 
            "type": "bulmaTable", 
            "data": {
                table:"Contact",
                title:"Architect & Designer's Contacts",
                caninsert:true,
                path: {
                    "create": "/contacts/c/",
                    "details": "/contacts/d/",
                    "edit": "/contacts/e/",
                },
                lists:[
                    { "label":"All Contacts", "name":"all", "default": true, "shared":true, 
                        cols:[
                            { "label":"Key", "name":"id", "format": "hidden", "fieldname":"id" },
                            { "label":"AccountId", "name":"AccountId", "format": "hidden", "fieldname":"AccountId" },
                            { "name": "Title", "label": "Title", "format":"text", "fieldname":"Title" },
                            { "name": "LastName", "label": "LastName", "format":"text", "fieldname":"LastName" },
                            { "name": "FirstName", "label": "FirstName", "format":"text", "fieldname":"FirstName" },
                            { "name": "Email", "label": "Email", "format":"text", "fieldname":"Email" },
                            { "name": "Phone", "label": "Phone", "format":"text", "fieldname":"Phone" },
                            { "name": "MobilePhone", "label": "MobilePhone", "format":"text", "fieldname":"MobilePhone" },
                        ],
                        "filters": [
                            { "fieldname": "AccountId", "operator": "EQ", "recordField": "Architect_Designer__c" }
                        ],
                        "order": [
                            { "fieldname": "Name", "operator": "ASC" }
                        ]
                    },
                    { "label":"Contacts", "name":"some", "default": true, "shared":true, 
                        cols:[
                            { "label":"Key", "name":"id", "format": "hidden", "fieldname":"id" },
                            { "label":"AccountId", "name":"AccountId", "format": "hidden", "fieldname":"AccountId" },
                            { "name": "Title", "label": "Title", "format":"text", "fieldname":"Title" },
                            { "name": "LastName", "label": "LastName", "format":"text", "fieldname":"LastName" },
                            { "name": "FirstName", "label": "FirstName", "format":"text", "fieldname":"FirstName" },
                            { "name": "Email", "label": "Email", "format":"text", "fieldname":"Email" },
                            { "name": "Phone", "label": "Phone", "format":"text", "fieldname":"Phone" },
                        ],
                        "filters": [
                            { "fieldname": "AccountId", "operator": "EQ", "recordField": "Architect_Designer__c" }
                        ],
                        "order": [
                            { "fieldname": "Name", "operator": "ASC" }
                        ]
                    },
                ],
                cols:[
                    { "label":"Key", "name":"id", "format": "hidden", "fieldname":"id" },
                    { "label":"Name", "name":"name", "format": "link", "linkname": "id", "fieldname":"name"},
                    { "label":"Industry", "name":"industry", "format": "text", "fieldname":"Industry"},
                    { "label":"BillingCity", "name":"city", "format": "text", "fieldname":"BillingCity"},
                    { "label":"BillingState", "name":"state", "format": "text", "fieldname":"BillingState"},
                ],
            }
        },
        {   "id": "deal_fields_contractor", 
            "type": "bulmaFieldSet2", 
            "data": {
                "table": "Opportunity",
                fields:[
                    { fieldname:"General_Contractor__c" },
                ]
            }
        },
        {   "id": "deal_fields_contractor_contacts", 
            "type": "bulmaTable", 
            "data": {
                table:"Contact",
                title:"General Contractor's Contacts",
                caninsert:true,
                path: {
                    "create": "/contacts/c/",
                    "details": "/contacts/d/",
                    "edit": "/contacts/e/",
                },
                lists:[
                    { "label":"All Contacts", "name":"all", "default": true, "shared":true, 
                        cols:[
                            { "label":"Key", "name":"id", "format": "hidden", "fieldname":"id" },
                            { "label":"AccountId", "name":"AccountId", "format": "hidden", "fieldname":"AccountId" },
                            { "name": "Title", "label": "Title", "format":"text", "fieldname":"Title" },
                            { "name": "LastName", "label": "LastName", "format":"text", "fieldname":"LastName" },
                            { "name": "FirstName", "label": "FirstName", "format":"text", "fieldname":"FirstName" },
                            { "name": "Email", "label": "Email", "format":"text", "fieldname":"Email" },
                            { "name": "Phone", "label": "Phone", "format":"text", "fieldname":"Phone" },
                            { "name": "MobilePhone", "label": "MobilePhone", "format":"text", "fieldname":"MobilePhone" },
                        ],
                        "filters": [
                            { "fieldname": "AccountId", "operator": "EQ", "recordField": "General_Contractor__c" }
                        ],
                        "order": [
                            { "fieldname": "Name", "operator": "ASC" }
                        ]
                    },
                ],
                cols:[
                    { "label":"Key", "name":"id", "format": "hidden", "fieldname":"id" },
                    { "label":"Name", "name":"name", "format": "link", "linkname": "id", "fieldname":"name"},
                    { "label":"Industry", "name":"industry", "format": "text", "fieldname":"Industry"},
                    { "label":"BillingCity", "name":"city", "format": "text", "fieldname":"BillingCity"},
                    { "label":"BillingState", "name":"state", "format": "text", "fieldname":"BillingState"},
                ],
            }
        },
        {   "id": "deal_form_products", 
            "type": "bulmaTable", 
            "data": {
                table:"OpportunityLineItem",
                title:"Products",
                caninsert:true,
                related:true,
                path: {
                    "create": "/projects/c/",
                    "details": "/projects/d/",
                    "edit": "/projects/e/",
                },
                lists:[
                    {  "label":"All Productcs", "name":"all", "default": true, "shared":true, 
                        "cols":[
                            { "id":"id", "label":"Key", "fieldname":"Id", "format": "hidden", },
                            { "id":"name", "label":"Name", "fieldname":"Name", "format": "link", "link_fieldname": "id", },
                            { "id":"Quantity", "label":"Quantity", "fieldname":"Quantity", "format": "number", },
                            { "id":"UnitPrice", "label":"UnitPrice", "fieldname":"UnitPrice", "format": "number", },
                            { "id":"TotalPrice", "label":"TotalPrice", "fieldname":"TotalPrice", "format": "number", },
                            { "id":"Discount", "label":"Discount", "fieldname":"Discount", "format": "number", },
                        ],
                        "filters": [ 
                            { "fieldname": "OpportunityId", "operator": "EQ", "recordField": "Id" }
                        ],
                        "order": [
                            { "fieldname": "SortOrder", "operator": "ASC" }
                        ]
                    },
                ],
                cols:[
                    { "id":"id", "label":"Key", "fieldname":"Id", "format": "hidden", },
                    { "id":"name", "label":"Name", "fieldname":"Name", "format": "link", "link_fieldname": "id", },
                    { "id":"Quantity", "label":"Quantity", "fieldname":"Quantity", "format": "number", },
                    { "id":"UnitPrice", "label":"UnitPrice", "fieldname":"UnitPrice", "format": "number", },
                    { "id":"TotalPrice", "label":"TotalPrice", "fieldname":"TotalPrice", "format": "number", },
                    { "id":"Discount", "label":"Discount", "fieldname":"Discount", "format": "number", },
                ],
            }
        },
        {   "id": "deal_form_products_engagements", 
            "type": "bulmaTable", 
            "data": {
                table:"engagements",
                title:"Products",
                caninsert:true,
                related:true,
                path: {
                    "create": "/projects/c/",
                    "details": "/projects/d/",
                    "edit": "/projects/e/",
                },
                lists:[
                    { "label":"All Engagements", "name":"all", "search": "*" },
                ],
                cols:[
                    { "label":"Key", "name":"id", "type": "hidden", "fieldname":"id" },
                    { "label":"hs_createdate", "name":"hs_createdate", "type": "link", "linkname": "id", "fieldname":"hs_createdate"},
                    { "label":"hs_engagement_type", "name":"hs_engagement_type", "type": "text","fieldname":"hs_engagement_type"},
                ],
            }
        },        
        {   "id": "deal_activities", 
            "type": "bulmaTimeline", 
            "data": {
                table:"engagements",
                title:"Activities",
                caninsert:true,
                related:true,
                lists:[
                    { "label":"All Engagements", "name":"all", "search": "*" },
                ],
                cols:[
                    { "label":"Key", "name":"id", "type": "hidden", "fieldname":"id" },
                    { "label":"hs_createdate", "name":"hs_createdate", "type": "link", "linkname": "id", "fieldname":"hs_createdate"},
                    { "label":"hs_engagement_type", "name":"hs_engagement_type", "type": "text","fieldname":"hs_engagement_type"},
                ],
            }    
        },
        {   "id": "c1",
            "type":"bulmaContainer",
            "data": {
                "name":"uno",
                "cells": [
                    {
                        "name": "uno", "width":12, "id": "deals_list", "type": "bulmaTable", 
                    },
                ]
            }
        },
        {   "id": "c2",
            "type":"bulmaContainer",
            "data": {
                "name":"due",
                "cells": [
                    { "name": "uno", "width":6, 
                    },
                    { "name": "due", "width":6, 
                    }
                ]
            }
        },
        {   "id": "bottombar",
            "type":"bulmaBottomBar",
            "data": {
                "tabs": [
                    { "name": "Home", "icon": "home", "action":"goto_home", }, 
                    { "name": "Tasks", "icon": "list-check", "action":"goto_tasks", }, 
                    { "name": "Search", "icon": "magnifying-glass", "action":"goto_search", }, 
                    { "name": "Buddy", "icon": "dog", "action":"activate_buddy", }, 
                ],
                "active_tab": "Buddy",
            }
        },
        {   "id": "table1", 
            "type": "bulmaTable", 
            "data": {
                table:"companies",
                title:"Accounts",
                lists:[
                    { "label":"All Projects", "name":"all", "search": "*" },
                    { "label":"My Projects", "name":"mine", "search": "*" },
                    { "label":"To be approved Projects", "name":"tbapproved", "search": "*" },
                ],
                cols:[
                    { "label":"uno", "name":"uno", "type": "link", "linkname": "id"},
                    { "label":"due", "name":"due", "type": "text"},
                    { "label":"tre", "name":"tre", "type": "text"},
                    { "label":"quattro", "name":"quattro", "type": "text"},
                ],
                rows:[
                    { "id": "xx", "uno": 1, "due": 2, "tre": 3, "quattro": 4}
                ],
            }
        },
        {   "id": "leads_actions", 
            "type": "bulmaActions", 
            "data": {
                actions:[
                    { name: "New", label: "New", action:"new_record", default: true, icon:"plus" },
                    { name: "Search", label: "Search", action:"search_record",  icon:"magnifying-glass" },
                ]
            }
        },
        {   "id": "tasks_list", 
            "type": "bulmaTable", 
            "data": {
                table:"Task",
                title:"Tasks",
                lists:[
                    {  "label":"All Task", "name":"all", "default": true, "shared":true, 
                        "cols":[
                            { "id":"id", "fieldname":"Id", "label":"Key", "format": "hidden" },
                            { "id":"Subject","fieldname":"Subject", "label":"Subject", "format": "link", "linkname": "id" },
                            { "id":"ActivityDate", "fieldname":"ActivityDate", "label":"ActivityDate", "format": "date", },
                            { "id":"Status", "fieldname":"Status", "label":"Status", "format": "text", },
                            { "id":"What", "fieldname":"What.Name", "label":"What", "format": "text", },
                            { "id":"Who", "fieldname":"Who.Name", "label":"Who", "format": "text", },
                        ],
                        "filters": [ 
                            { "fieldname": "id", "operator": "NEQ", "value": "" }
                        ],
                        "order": [
                            { "fieldname": "CreatedDate", "operator": "DESC" }
                        ],
                        "actions": [
                            { "fieldname": "CreatedDate", "operator": "DESC" }
                        ]
                    }
                ],
                default_cols:[
                    { "id":"id", "fieldname":"Id", "label":"Key", "format": "hidden" },
                    { "id":"Subject","fieldname":"Subject", "label":"Subject", "format": "link", "linkname": "id" },
                    { "id":"ActivityDate", "fieldname":"ActivityDate", "label":"ActivityDate", "format": "date", },
                    { "id":"Status", "fieldname":"Status", "label":"Status", "format": "text", },
                    { "id":"What", "fieldname":"What.Name", "label":"What", "format": "text", },
                    { "id":"Who", "fieldname":"Who.Name", "label":"Who", "format": "text", },
                ],
            }
        },
        {   "id": "leads_list", 
            "type": "bulmaTable", 
            "data": {
                table:"Lead",
                title:"Leads",
                lists:[
                    {  "label":"All Customers", "name":"all", "default": true, "shared":true, 
                        "cols":[
                            { "id":"id", "fieldname":"Id", "label":"Key", "format": "hidden" },
                            { "id":"name","fieldname":"Company", "label":"Company", "format": "link", "linkname": "id" },
                            { "id":"Title", "fieldname":"Title", "label":"Title", "format": "text", },
                            { "id":"LastName", "fieldname":"LastName", "label":"LastName", "format": "text", },
                            { "id":"FirstName", "fieldname":"FirstName", "label":"FirstName", "format": "text", },
                            { "id":"Email", "fieldname":"Email", "label":"Email",  "format": "text" },
                            { "id":"MobilePhone", "fieldname":"MobilePhone", "label":"MobilePhone",  "format": "text" },
                        ],
                        "filters": [ 
                            { "fieldname": "id", "operator": "NEQ", "value": "" }
                        ],
                        "order": [
                            { "fieldname": "CreatedDate", "operator": "DESC" }
                        ],
                        "actions": [
                            { "fieldname": "CreatedDate", "operator": "DESC" }
                        ]
                    }
                ],
                default_cols:[
                    { "id":"id", "fieldname":"Id", "label":"Key", "format": "hidden" },
                    { "id":"name","fieldname":"Company", "label":"Company", "format": "link", "linkname": "id" },
                    { "id":"Title", "fieldname":"Title", "label":"Title", "format": "text", },
                    { "id":"LastName", "fieldname":"LastName", "label":"LastName", "format": "text", },
                    { "id":"FirstName", "fieldname":"FirstName", "label":"FirstName", "format": "text", },
                    { "id":"Email", "fieldname":"Email", "label":"Email",  "format": "text" },
                    { "id":"MobilePhone", "fieldname":"MobilePhone", "label":"MobilePhone",  "format": "text" },
                ],
            }
        },
        {   "id": "lead_form",
            "type":"bulmaSubTab",
            "data": {
                "tabs": [
                    {
                        "name": "Name", "id": "lead_form_main", "type": "bulmaSections",
                    }, {
                        "name": "Location", "id": "lead_form_location", "type": "bulmaSections",
                    }, {
                        "name": "Player", "id": "deals_list", "type": "bulmaTable",
                    }, {
                        "name": "Products", "id": "table2", "type": "bulmaTable",
                    }, {
                        "name": "Other", "id": "table2", "type": "bulmaTable",
                    }, {
                        "name": "Activities", "id": "table2", "type": "bulmaTable",
                    }
                ],
                "active_tab": "Name",
            }
        },
        {   "id": "lead_form_main", 
            "type": "bulmaSections", 
            "data": {
                title:"Main Data",
                actionid: "action_1",
                rows:[
                    {  title: "Main Data", opened: true,  "id": "lead_list_1", "type": "bulmaFieldSet2", },
                    {  title: "Other Data", opened: false,  "id": "lead_list_2", "type": "bulmaFieldSet", },
                ],
            }
        },
        {   "id": "lead_form_location", 
            "type": "bulmaSections", 
            "data": {
                title:"Location",
                actionid: "action_1",
                rows:[
                    {  title: "Location Info", opened: true,  "id": "lead_fields_location", "type": "bulmaFieldSet2", },
                    {  title: "Map", opened: true,  "id": "deal_fields_location_map", "type": "bulmaMap", },
                ],
            }
        },
        {   "id": "action_1", 
            "type": "bulmaActions", 
            "data": {
                actions:[
                    { name: "save", label: "Save", action:"save_record", default: true, icon:"check", states:['NEW', 'EDIT'] },
                    { name: "cancel", label: "Cancel", action:"cancel_record", default: false, icon:"check", states:['NEW', 'EDIT'] },
                    { name: "edit", label: "Edit", action:"edit_record", default: true, icon:"pencil", states:['READY'] },
                    { name: "approval", label: "Approvals", action:"submit_record", disabled: true,  icon:"stamp", states:['READY'] },
                    { name: "delete", label: "Delete", action:"delete_record", disabled: true,  icon:"eraser", states:['READY']  },
                ]
            }
        },
        {   "id": "action_2", 
            "type": "bulmaActions", 
            "data": {
                actions:[
                    { name: "Add", label: "Select", action:"save_record", default: true, icon:"check", states:['NEW', 'EDIT', 'READY'] },
                    { name: "cancel", label: "Back", action:"cancel_record", default: false, icon:"close", states:['NEW', 'EDIT', 'READY'] },
                ]
            }
        },
        {   "id": "lead_fields_location", 
            "type": "bulmaFieldSet2", 
            "data": {
                "table": "lead",
                dual:true,
                fields:[
                    { fieldname:"city" },
                    { fieldname:"state" },
                    { fieldname:"street" },
                    { fieldname:"no_location" },
                ]
            }
        },
        {   "id": "lead_list_1", 
            "type": "bulmaFieldSet2", 
            "data": {
                "table": "leads",
                dual:true,
                fields:[
                    { fieldname:"company" },
                    { fieldname:"lastname" },
                    { fieldname:"firstname" },
                    { fieldname:"email" },
                ]
            }
        },
        {   "id": "lead_list_2", 
            "type": "bulmaFieldSet", 
            "data": {
                fields:[
                    {label: "ciaoneA1", name: "ciaoneA1", success:true, message:"ok va bene", type: "text", placeholder: "eccomi", options: [] },
                    {label: "ciaoneA2", name: "ciaoneA2", fail:true, message:"male! male!",type: "textarea", placeholder: "eccomi2", rows:2, options: [] },
                    {label: "ciaoneA3", name: "ciaoneA3", type: "checkbox" },
                    {label: "ciaoneA4", name: "ciaoneA4", type: "radio", placeholder: "eccomi2", rows:2, options: [{"label":"opt1", "value":1}, {"label":"opt2", "value":2}] },
                    {label: "ciaoneA5", name: "ciaoneA5", type: "picklist", placeholder: "eccomi2", rows:2, options: [{"label":"Yes", "value":"Yes"}, {"label":"No", "value":"No"}] },
                ]
            }
        },
        {   "id": "accounts_list", 
            "type": "bulmaTable", 
            "data": {
                table:"Account",
                title:"Accounts",
                "process": "customers",                
                lists:[
                    {  "label":"All Customers", "name":"all", "default": true, "shared":true, 
                        "cols":[
                            { "id":"id", "fieldname":"Id", "label":"Key", "format": "hidden" },
                            { "id":"name","fieldname":"Name", "label":"Company", "format": "link", "linkname": "id" },
                            { "id":"city", "fieldname":"BillingCity", "label":"City", "format": "text", },
                            {  "id":"state", "fieldname":"BillingState", "label":"State", "format": "text", },
                            { "id":"industry", "fieldname":"industry", "label":"Industry",  "format": "text" },
                        ],
                        "filters": [ 
                            { "fieldname": "id", "operator": "NEQ", "value": "" }
                        ],
                        "order": [
                            { "fieldname": "CreatedDate", "operator": "DESC" }
                        ],
                        "actions": [
                            { "fieldname": "CreatedDate", "operator": "DESC" }
                        ]
                    }
                ],
                default_cols:[
                    { "label":"Key", "name":"id", "type": "hidden", "fieldname":"id" },
                    { "label":"Company", "name":"name", "type": "link", "linkname": "id", "fieldname":"name"},
                    { "label":"City", "name":"city", "type": "text", "fieldname":"BillingCity"},
                    { "label":"State", "name":"state", "type": "text", "fieldname":"BillingState"},
                    { "label":"Industry", "name":"industry", "type": "text", "fieldname":"industry"},
                ],
            }
        },
        {   "id": "table2",
            "type": "bulmaTable", 
            "data": {
                table:"tasks",
                title:"Tasks",
                lists:[
                    { "label":"All Tasks", "name":"all", "search": "*" },
                    { "label":"Open Tasks", "name":"mine", "search": "*" },
                    { "label":"Recenv Tasks", "name":"tbapproved", "search": "*" },
                ],

                cols:[
                    { "label":"uno", "name":"uno", "type": "link", "linkname": "id"},
                    { "label":"due", "name":"due", "type": "text"},
                    { "label":"tre", "name":"tre", "type": "text"},
                    { "label":"quattro", "name":"quattro", "type": "text"},
                ],
                rows:[
                    { "id": "xx", "uno": 1, "due": 2, "tre": 3, "quattro": 4}
                ],
            }
        },
    ],
    database: [
        { table: "contacts", 
            fields: [
                { "name": "id", "type":"hidden" },
                { "name": "firstname", "label": "Firstname", "type":"text" },
                { "name": "lastname", "label": "Lastname", "type":"text" },
                { "name": "email", "label": "Email", "type":"email" },
            ]
        },
        { table: "companies", 
            fields: [
                { "name": "id", "type":"hidden" },
                { "name": "name", "type":"text" },
                { "name": "city", "type":"text" },
                { "name": "state", "type":"text" },
                { "name": "industry", "type":"text" },
                { "name": "hubspot_owner_id", "type":"text" },
            ]
        },
        { table: "Account", 
            fields: [
                { "name": "Id", "type":"hidden" },
                { "name": "Name", "label": "Name", "type":"text" },
                { "name": "Type", "label": "Type", "type":"text" },
                { "name": "Industry", "label": "Industry", "type":"text" },
                { "name": "BillingState", "label": "Billing State", "type":"text" },
                { "name": "BillingCity", "label": "Billing City", "type":"text" },
                { "name": "BillingStreet", "label": "BillingStreet", "type":"text" },
            ]
        },
        { table: "Lead", 
            fields: [
                { "name": "Id", "type":"hidden" },
                { "name": "Title", "label": "Title", "type":"text" },
                { "name": "LastName", "label": "LastName", "type":"text" },
                { "name": "FirstName", "label": "FirstName", "type":"text" },
                { "name": "Company", "label": "Company", "type":"text" },
                { "name": "Email", "label": "Email", "type":"text" },
                { "name": "Phone", "label": "Phone", "type":"text" },
                { "name": "MobilePhone", "label": "MobilePhone", "type":"text" },
            ]
        },
        { table: "Contact", 
            fields: [
                { "name": "Id", "type":"hidden" },
                { "name": "AccountId", "type":"hidden" },
                { "name": "Title", "label": "Title", "type":"text" },
                { "name": "LastName", "label": "LastName", "type":"text" },
                { "name": "FirstName", "label": "FirstName", "type":"text" },
                { "name": "Email", "label": "Email", "type":"text" },
                { "name": "Phone", "label": "Phone", "type":"text" },
                { "name": "MobilePhone", "label": "MobilePhone", "type":"text" },
            ]
        },
        { table: "Task", 
            fields: [
                { "name": "Id", "type":"hidden" },
                { "name": "Subject", "label": "Subject", "type":"text" },
                { "name": "ActivityDate", "label": "ActivityDate", "type":"date" },
                { "name": "Status", "label": "Status", "type":"text" },
                { "name": "What.Name", "label": "What", "type":"text" },
                { "name": "Who.Name", "label": "Who", "type":"text" },
            ]
        },
        { table: "Opportunity", 
            fields: [
                { "name": "Id", "type":"hidden" },
                { "name": "Name", "label": "Project Name", "type":"text" },

                { "name": "StageName", "label": "Status", "type":"text" },
                { "name": "Probability", "label": "Probability %", "type":"text" },

                { "name": "Type", "label": "Project Type", "type":"text" },

                { "name": "Sales_Brand__c", "label": "Sale Brand", "type":"text" },
                { "name": "Material_Type__c", "label": "Material Type", "type":"text" },
                { "name": "Industry__c", "label": "Industry", "type":"text" },
                { "name": "Bulletin__c", "label": "Bulletin", "type":"text" },

                // { "name": "Architect_Designer__c", "label": "Architect / Designer", "type":"text" },
                { "name": "Architect_Designer__c", "label": "Architect / Designer", "type":"lookup", "process": "lookup_account", "list":"architect" },
                { "name": "General_Contractor__c", "label": "General Contractor", "type":"text" },

                { "name": "City__c", "label": "City", "type":"text" },
                { "name": "State__c", "label": "State", "type":"text" },
                { "name": "Street__c", "label": "Street", "type":"text" },
                { "name": "No_Location__c", "label": "Number of location / annum", "type":"text" },
            ]
        },
        { table: "OpportunityLineItem", 
            fields: [
                { "name": "id", "type":"hidden" },
                { "name": "Name", "label": "SKU #", "type":"text" },
                { "name": "Quantity", "label": "Quantity", "type":"text" },
                { "name": "UnitPrice", "label": "UnitPrice", "type":"text" },
                { "name": "TotalPrice", "label": "TotalPrice", "type":"text" },
                { "name": "Discount", "label": "Discount", "type":"text" },
                { "name": "Description", "label": "Description", "type":"text" },
                { "name": "Product2Id", "label": "Product2Id", "type":"text" },
                { "name": "ProductCode", "label": "ProductCode", "type":"text" },
            ]
        },        
        { table: "deals", 
            fields: [
                { "name": "id", "type":"hidden" },
                { "name": "dealname", "label": "Project Name", "type":"text" },
                { "name": "dealstage", "label": "Status", "type":"text" },
                { "name": "amount", "type":"currency" },
                { "name": "prj_type", "label": "Project Type","type":"text" },
                { "name": "sales_brand", "label": "Sales Brand","type":"text" },
                { "name": "material_type", "label": "Material Type","type":"text" },
                { "name": "industry", "label": "Industry", "type":"text" },
                { "name": "bulletin", "label": "Bulletin Number (if applicable)", "type":"text" },
                { "name": "probability", "label": "Acquisition % Probability", "type":"text" },

                { "name": "tile_contractor", "label": "Tile Contractor Name", "type":"text" },
                { "name": "notes", "label": "Job Notes", "type":"text" },

                { "name": "city", "label": "City", "type":"text" },
                { "name": "state", "label": "State", "type":"text" },
                { "name": "street", "label": "Street", "type":"text" },
                { "name": "no_location", "label": "Number of location / annum", "type":"text" },

                { "name": "creation_date", "label": "Creation Date", "type":"date" },
                { "name": "owner", "label": "Submitted by", "type":"text" },

            ]
        },
        { table: "line_items", 
            fields: [
                { "name": "id", "type":"hidden" },
                { "name": "name", "label": "SKU #", "type":"text" },
                { "name": "quantity", "label": "quantity", "type":"text" },
                { "name": "price", "label": "price", "type":"text" },
                { "name": "amount", "label": "amount", "type":"text" },
                { "name": "discount", "label": "discount", "type":"text" },
                { "name": "hs_discount_percentage", "label": "hs_discount_percentage", "type":"text" },
                { "name": "description", "label": "description", "type":"text" },
                { "name": "hs_product_id", "label": "hs_product_id", "type":"text" },
                { "name": "hs_product_type", "label": "hs_product_type", "type":"text" },
                { "name": "hs_sku", "label": "hs_sku", "type":"text" },
            ]
        },
        { table: "product", 
            fields: [
                { "name": "id", "type":"hidden" },
                { "name": "name", "label": "SKU #", "type":"text" },
                { "name": "discontinued", "label": "To be discontinued", "type":"checkbox" },
                { "name": "collection", "label": "Collection", "type":"text" },
                { "name": "sales_brand", "label": "Sales Brand", "type":"text" },
                { "name": "color", "label": "Color", "type":"text" },
                { "name": "shape", "label": "Shape", "type":"text" },
                { "name": "unit", "label": "UoM", "type":"text" },
                { "name": "qty", "label": "Quantity", "type":"decimal" },
                { "name": "price", "label": "Price Quoted", "type":"currency" },
                { "name": "total", "label": "Total", "type":"formula", "formula":"qty*price" },
                { "name": "transfer", "label": "From dist./Branch to NA", "type":"decimal", },
            ]
        },
        { table: "engagements", 
            fields: [
                { "name": "id", "type":"hidden" },
                { "name": "hs_engagement_type", "label": "hs_engagement_type", "type":"text" },
                { "name": "hs_createdate", "label": "hs_createdate", "type":"text" },
                { "name": "hs_lastmodifieddate", "label": "hs_lastmodifieddate", "type":"text" },
            ]
        },
    ]
}
<template lang="html">

  <div class="columns is-multiline is-1" v-bind:id="id" >
    <div class="column" v-bind:class="{'is-half':config.dual,'is-full':!config.dual}" v-for="(field, index) in fields" v-bind:key="field.name">
      <bulmaField  v-bind:id="field.name" v-bind:data="field" v-bind:table="config.table"></bulmaField>
    </div>
  </div>

</template>

<script>

export default {
  name: 'bulmaFieldSet2',
  components: {},
  props: {
    id: String,
    recordid: String,
  },
  data: function () {
      return {
    }
  },
  watch: {
  },
  computed: {
    fields: function() {
      if (!this.$store.state.database.has(this.config.table)) return [];
      let table=this.$store.state.database.get(this.config.table);
      let solvedfields=[]
      this.config.fields.forEach( f => {
        if (table.has(f.fieldname)) {
          solvedfields.push(table.get(f.fieldname));
        }
      });
      return solvedfields;
    },
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
  },
  mounted: function() {
  },
  filters: {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>

<template lang="html">
  <article class="message" v-bind:id="id" >

    <div class="block" >

      <div class="message-header">
        <p>{{ config.title }}</p>

        <button v-on:click="loadMap()">Update Map</button>

      </div>

      <div class="message-body">
        <div id="mymap" style="height: 600px;">

        </div>

      </div>

    </div>

  </article>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"

export default {
  name: 'bulmaMap',
  components: {
  },
  computed: {
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
    busy: function() {
      return this.$store.state.busy;
    }
  },
  watch: {
    config: function(newVal) {
      if (newVal && this.loader) this.loadMap();
    }
  },
  props: {
    id: String,
  },
  data() {
    return {
      loader:{},
      map:{}
    }
  },
  mounted: function() {
    this.loader = new Loader({ apiKey: this.config.apiKey, version: "weekly", });
    this.loadMap();
  },
  async created() {
  },
  methods: {
    loadMap: function() {
      this.loader.load().then(async () => {
        // eslint-disable-next-line no-undef
        const { Map } = await google.maps.importLibrary("maps");

        this.map = new Map(document.getElementById("mymap"), {
          center: { lat: this.config.latitude, lng: this.config.longitude },
          zoom: 8,
        });
      });

    }
  }
}
</script>

<style scoped>
</style>

export default {
  "total": 4468,
  "results": [
    {
      "id": "1",
      "properties": {
        "createdate": "2023-05-04T09:36:58.895Z",
        "email": "emailmaria@hubspot.com",
        "firstname": "Maria",
        "hs_object_id": "1",
        "lastmodifieddate": "2024-04-18T15:53:56.112Z",
        "lastname": "Johnson (Sample Contact)"
      },
      "createdAt": "2023-05-04T09:36:58.895Z",
      "updatedAt": "2024-04-18T15:53:56.112Z",
      "archived": false
    },
    {
      "id": "51",
      "properties": {
        "createdate": "2023-05-04T09:36:59.265Z",
        "email": "bh@hubspot.com",
        "firstname": "Brian",
        "hs_object_id": "51",
        "lastmodifieddate": "2024-04-18T15:54:25.954Z",
        "lastname": "Halligan (Sample Contact)"
      },
      "createdAt": "2023-05-04T09:36:59.265Z",
      "updatedAt": "2024-04-18T15:54:25.954Z",
      "archived": false
    },
    {
      "id": "101",
      "properties": {
        "createdate": "2023-05-08T14:55:05.811Z",
        "email": "thanujaguna1997@gmail.com",
        "firstname": "Thanuja",
        "hs_object_id": "101",
        "lastmodifieddate": "2023-05-15T08:12:51.644Z",
        "lastname": "Gunasekhar"
      },
      "createdAt": "2023-05-08T14:55:05.811Z",
      "updatedAt": "2023-05-15T08:12:51.644Z",
      "archived": false
    },
    {
      "id": "201",
      "properties": {
        "createdate": "2023-05-09T09:06:25.737Z",
        "email": "elena.cornale@gnet.it",
        "firstname": "Elena",
        "hs_object_id": "201",
        "lastmodifieddate": "2024-04-10T16:06:21.036Z",
        "lastname": "Cornale"
      },
      "createdAt": "2023-05-09T09:06:25.737Z",
      "updatedAt": "2024-04-10T16:06:21.036Z",
      "archived": false
    },
    {
      "id": "301",
      "properties": {
        "createdate": "2023-05-15T08:26:56.221Z",
        "email": "mario.rossi@gmail.com",
        "firstname": "Mario",
        "hs_object_id": "301",
        "lastmodifieddate": "2023-05-15T08:27:11.061Z",
        "lastname": "Rossi"
      },
      "createdAt": "2023-05-15T08:26:56.221Z",
      "updatedAt": "2023-05-15T08:27:11.061Z",
      "archived": false
    },
    {
      "id": "351",
      "properties": {
        "createdate": "2023-05-15T08:27:47.275Z",
        "email": "martina.test@gmail.com",
        "firstname": "Laura",
        "hs_object_id": "351",
        "lastmodifieddate": "2023-05-19T06:00:05.563Z",
        "lastname": "Rossi"
      },
      "createdAt": "2023-05-15T08:27:47.275Z",
      "updatedAt": "2023-05-19T06:00:05.563Z",
      "archived": false
    },
    {
      "id": "401",
      "properties": {
        "createdate": "2023-05-15T08:28:57.402Z",
        "email": "roberta.bianchi@gmail.com",
        "firstname": "Roberta",
        "hs_object_id": "401",
        "lastmodifieddate": "2023-05-15T08:29:20.525Z",
        "lastname": "Bianchi"
      },
      "createdAt": "2023-05-15T08:28:57.402Z",
      "updatedAt": "2023-05-15T08:29:20.525Z",
      "archived": false
    },
    {
      "id": "451",
      "properties": {
        "createdate": "2023-05-15T08:30:23.334Z",
        "email": "luca.borici@gmail.com",
        "firstname": "Luca",
        "hs_object_id": "451",
        "lastmodifieddate": "2023-05-15T08:30:43.758Z",
        "lastname": "Borici"
      },
      "createdAt": "2023-05-15T08:30:23.334Z",
      "updatedAt": "2023-05-15T08:30:43.758Z",
      "archived": false
    },
    {
      "id": "501",
      "properties": {
        "createdate": "2023-05-16T09:03:18.175Z",
        "email": "margentero@gnet.it",
        "firstname": "Martina",
        "hs_object_id": "501",
        "lastmodifieddate": "2024-04-04T12:54:50.291Z",
        "lastname": "Argentero"
      },
      "createdAt": "2023-05-16T09:03:18.175Z",
      "updatedAt": "2024-04-04T12:54:50.291Z",
      "archived": false
    },
    {
      "id": "651",
      "properties": {
        "createdate": "2023-05-24T13:49:01.771Z",
        "email": "info@carmeusetrading.com",
        "firstname": "Mario",
        "hs_object_id": "651",
        "lastmodifieddate": "2023-12-21T09:22:18.018Z",
        "lastname": "Rossi"
      },
      "createdAt": "2023-05-24T13:49:01.771Z",
      "updatedAt": "2023-12-21T09:22:18.018Z",
      "archived": false
    }
  ],
  "paging": {
    "next": {
      "after": "10"
    }
  }
}
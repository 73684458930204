<template lang="html">

  <div class="" v-bind:id="id" >
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-5">{{ config.title }}</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">

          <!-- SEARCH -->
          <!-- <button class="button is-rounded is-small">
            <span class="icon" v-on:click="search()"><i class="fas fa-magnifying-glass"></i></span>
          </button> -->

          <div class="field has-addons mb-0">
            <div v-if="showInput" class="control">
              <input type="text" class="input is-small is-rounded" v-model="inputSearch" placeholder="Search">
            </div>
            <div class="control">
              <button class="button is-rounded is-small" @click="showInput = !showInput">
                <span class="icon"><i class="fas fa-magnifying-glass"></i></span>
              </button>
            </div>
          </div>

          <!-- ADD -->
          <button v-if="config.caninsert" class="button is-rounded is-small">
            <span class="icon" v-on:click="showAddRecord()"><i class="fas fa-plus"></i></span>
          </button>

          <button class="button is-rounded is-small"> <span class="icon" v-on:click="loadRows()"><i class="fas fa-rotate-right"></i></span> </button>
          <!--
        </div>
        <div class="level-item">
          -->
          <div class="select is-rounded is-small" v-if="config.lists.length > 1">
            <select v-model="current_list_name" v-on:change="changeList">
              <option v-for="list in config.lists" v-bind:key="list.name" v-bind:value="list.name">{{ $t(list.label) }}</option>
            </select>
            <!-- TODO: change my default-->
          </div>
        </div>
      </div>
    </div>

    <div class="table-container">
      <table class="table is-narrow">

        <thead>
          <tr>
            <!-- TODO: filter -->
            <!-- TODO: sort -->
            <th v-if="config.checkbox"><input v-if="config.checkbox == 'multi'" type="checkbox" v-model="checkedAllOptions" @click="selectAll(checkedAllOptions)" /></th>
            <th v-for="(col, index) in current_list_cols" v-bind:key="col.id" style="vertical-align:top"  v-on:click="$emit('filter',index)" >
              <span v-bind:style=" (wrapheader?'white-space: normal':'') " >{{col.label}}</span>
              <span v-if="col.filtered" class="icon"><i class="fas fa-filter"></i></span>
            </th>
          </tr>
        </thead>

        <tbody>
            <tr v-for="record in current_page_records" v-bind:key="record.id"><!-- v-on:click="$emit('row',record)" -->
              
              <!-- <td v-if="config.checkbox"><label class="checkbox"><input type="checkbox" /></label></td> -->
              <td v-if="record.show && config.checkbox">
                <input :type="config.checkbox == 'multi' ? 'checkbox':'radio'" :id="record.Id" :value="record.Id" v-model="selected_list_id" @change="saveRecords(record)"/>
              </td>

              <td v-if="record.show" v-for="col in current_list_cols" v-bind:key="col.id" v-bind:style="'text-align:'+align(col.format)" >

                <a v-if="col.format=='link'" href="#" v-on:click.prevent="select(record, col)">
                  {{ filter( getFieldValue(record, col.fieldname), col.format ) }}
                </a>

                <span v-if="col.format!='link' && col.format!='hidden'">
                  <span v-if="col.format=='alert' && record[col.name]" class="icon"><i class="fas fa-bell"></i></span>
                  {{ filter( getFieldValue(record, col.fieldname), col.format ) }}
                </span>

              </td>

            </tr>
        </tbody>
      </table>
    </div>
  
    <nav class="level" v-if="$store.state.busy">
      <div class="level-item has-text-centered">
        <button class="button is-loading" >Loading</button>
      </div>
    </nav>
    <bulmaPagination v-if="!$store.state.busy" v-bind:maxPages="maxPages" v-bind:currentPage="currentPage"  v-on:goto="loadPage" v-on:next="loadNextPage" v-on:previous="loadPreviousPage"></bulmaPagination>
  </div>

</template>

<script>
/* { "id":"id", "label":"Key", "fieldname":"Id", "format": "hidden", },*/

import numeral from 'numeral'
import moment from 'moment'

export default {
  name: 'bulmaTable',
  components: {},
  props: {
    id: String,
    
    wrapheader: Boolean,
    wrapcell: Boolean,
  },
  data: function () {
      return {
        current_list: {},
        current_list_name:'',

        records: [],
        currentPage:1,
        nrecbypage:8,

        selected_list_id:[],
        checkedAllOptions: false,
        variabile: '',

        showInput: false,
        inputSearch: '',
    }
  },
  watch: {
    inputSearch (input) {
      console.log('search: '+input, typeof(input))
      for(var record of this.records) {
        if(input != '') {
          record.show = false
          for(var col of this.current_list_cols) {
            var value = this.getFieldValue(record, col.fieldname)
            // console.log('value: '+value, typeof(value))
            if(value != null && value.toLowerCase().indexOf(input.toLowerCase()) != -1) record.show = true
          }
        } else record.show = true
      }
    },
  },
  computed: {
    current_page_records: function() {
      let s=(this.currentPage-1)*this.nrecbypage;
      let e=s+this.nrecbypage;
      return this.records.slice(s,e);
    },

    current_list_cols: function() {
      if (!this.current_list || !this.current_list.cols) return this.current_list;
      return this.current_list.cols.filter( (c) => { return c.format!='hidden'; })
    },
    maxPages: function() {
      let res=this.$store.getters.getRecordsList(this.id);
      if (res) {
        return Math.ceil(res.totalRecords/this.nrecbypage);
      }
      return 0;
    },
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
    checkedAll() {
      if(this.selected_list_id.length == this.current_page_records.length) return true
      if(this.checkedAllOptions) return true
      return false
    }
  },
  mounted: function() {
    // current list
    this.current_list=this.config.lists.find( (l) => { return l.default; });
    if (!this.current_list) this.config.lists[0];
    this.current_list_name=this.current_list.name;

    if (this.config.nrecbypage) this.nrecbypage=this.config.nrecbypage;

    if(this.config.checkbox) {
      if(this.config.checkbox == 'multi') this.selected_list_id.push(this.$store.state.lookup_field_value)
      else this.selected_list_id = this.$store.state.lookup_field_value
    }
    console.log('this.selected_list_id',this.selected_list_id)

    // hydration
    if (this.config.hydration) {
      this.hydrate();
    } else {
      this.loadRows();
    }
  },
  methods: {
    localAction: function(action) {
      switch (action.action) {
        case 'new_record':
          this.create();
          break;
        case 'search_record':
          this.search();
        break;
        case 'reload_records':
          this.loadRows();
        break;
      }

    },
    changeList: function() {
      console.log("changeList", this.current_list_name);
      this.current_list=this.config.lists.find( (l) => { return this.current_list_name==l.name; });
      console.log("current_list", this.current_list);

      this.loadRows();
    },
    create: function() {
      // new_record
      let m={ message: 'new_record', payload: this.config.process};
      this.$store.commit('POST_MESSAGE', m);
    },
    // search: function() {
    //   alert('tbd sorry')
    // },
    loadNextPage: async function() {
      this.currentPage++;
    },
    loadPreviousPage: async function() {
      this.currentPage--;
    },
    loadPage: async function(p) {
      this.currentPage=p;
    },
    loadRows: async function() {
      this.records=[];
      var payload={
        id: this.id,
        tablename: this.config.table,
        cols: this.current_list.cols,
        filters: this.current_list.filters,
        order: this.current_list.order,
      }

      await this.$store.dispatch('load_records', payload );

      let data=this.$store.getters.getRecordsList(this.id);
      this.records=data.records
    },
    // TODO: hydrate is for hubspot, currently broken :)
    hydrate: async function() {
      this.records=[];

      let records=this.$store.getters.getRecordAssoc(this.config.table);
      this.$store.commit('SET_RECORDS_DATA', { id:this.id, data:records});
      let p=[];
      this.config.cols.forEach( f => {
        if (f.fieldname!='id') p.push(f.fieldname);
      });

      let res=await this.$store.dispatch('hydrate_records', { id: this.id, object: this.config.table, properties:p});
      if (res.data) {
        res.data.results.forEach( (r) => {
          r.properties.id=r.id;
          this.records.push(r.properties);
        })
      }
    },
    filter: function(v, type) {
      if (!v) return v;

      // TODO: locale
      switch(type) {
        case 'hidden':
          return;
        case 'date':
          var d=new moment(v);
          return d.format('DD/MM/YYYY');
        case 'datetime':
          var dt=new moment(v);
          return dt.format('DD/MM/YYYY');
        case 'decimal':
          return numeral(v).format('0,0');
        case 'currency':
          return numeral(v).format('$ 0,0.00');
        case 'alert':
          return '';
        case 'truncate':
            return v.substr(0,32);
      }
      return v;
    },
    getFieldValue: function(obj, path) {
      // eslint-disable-next-line no-redeclare
      for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
        if (obj)
          obj = obj[path[i]];
      }
      return obj;
    },
    align: function(type) {
      switch(type) {
        case 'currency':
        case 'decimal':
          return 'right';
      }
      return 'left';
    },
    select: function(record, col) {
      console.log('select: ', record, col)
      let id=col.link_fieldname;
      this.$store.commit('SET_RECORDS_ID', { id: this.id, data:record[id] });
      this.$store.commit('SET_RECORDS_STATE', { id: this.id, state:'TOLOAD' });
      this.$router.push(this.config.path.details+record[id]);
//      this.$emit('click', index);
    },
    selectAll(checkedAll) {
      console.log(checkedAll)
      this.selected_list_id = []
      if(!checkedAll) {
        for(var r of this.current_page_records) {
          // this.selected_list_name.push(r[this.config.Id.fieldname])
          this.selected_list_id.push(r.Id)
        }
        this.saveRecords()
      }
    },
    showAddRecord() {
      console.log('--> showAddRecord');

      var object = this.$store.getters.getObject(this.config.addForm.id)
      var data = {
        id: this.config.addForm.id,
        title: object.data.title,
        type: object.type
      }
      this.$store.commit('POST_MESSAGE', { message: 'goto_search', payload: data} );
      // this.$store.commit('SET_RECORDS_STATE', {id: this.$store.state.global_state_process, state:'NEW'});
      this.$store.commit('SET_RECORDS_STATE', {id: this.config.addForm.id, state:'NEW'});
      this.$store.commit('SET_RECORDS_DATA', {id: this.config.addForm.id, data:{}});
    },
    saveRecords() {
      console.log('saveRecords')
      // al momento solo per la single select
      var r = this.$store.getters.getRecordData
      r[this.$store.state.lookup_field] = this.selected_list_id
      this.$store.commit('SET_RECORDS_DATA', r);
    }
  }
}
</script>

<style scoped>
.bloccato {
  position: sticky;
  top: 0;
  z-index: 1;
}
.is-text-overflow {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
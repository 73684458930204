<template lang="html">

  <div class="block" v-bind:id="id" >
    <div class="tabs is-boxed">
      <ul>
        <li v-on:click="tabClick(index)" v-for="(tab, index) in config.tabs" v-bind:key="tab.name" v-bind:class="{ 'is-active': tab.name==config.active_tab }">
          <a>{{$t(tab.name)}}</a>
        </li>
      </ul>
    </div>
    <div class="">
        <component v-if="active_type" v-bind:is="active_type" v-bind:id="active_id"></component>
      </div>
  </div>


</template>

<script>

export default {
  name: 'bulmaSubTab',
  components: {
  },
  props: {
    id: String,
  },
  computed: {
    active_type: function() {
      let t=this.config.tabs.find( (t) => { return t.name==this.config.active_tab})
      if (t) return t.type;
      return "";
    },
    active_id: function() {
      let t=this.config.tabs.find( (t) => { return t.name==this.config.active_tab})
      if (t) return t.id;
      return "";
    },
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
    editmode: function() {
      return this.$store.getters.getEditMode(this.id);
    },
    romode: function() {
      return this.$store.getters.getROMode(this.id);
    }
  },
  data() {
    return {
      burger_open: false,
    }
  },
  methods: {
    updateConfig: function( name, val ) {
      var c=this.config;
      c[name]=val;
      var payload={ id:this.id, cfg:{ id:this.id, type:this.type, data:c} }      
      this.$store.commit('SET_CONFIG', payload);
    },
    tabClick: function(tabindex) {
      var t=this.config.tabs[tabindex];
      this.updateConfig("active_tab", t.name);
    },
  }
}
</script>

<style scoped>

</style>

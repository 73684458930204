import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home, props: true },
  { path: '/:process', name: 'Object_Home', component: Home, props: true },
  { path: '/:process/:verb', name: 'Object_New', component: Home, props: true },
  { path: '/:process/:verb/:recordid', name: 'Object_Detail', component: Home, props: true },
]

const router = new VueRouter({
  routes
})

export default router

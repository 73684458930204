<template lang="html">
  
  <div v-bind:id="id" >
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <img src="/images/logo/Florida-Tile-logo.png"/>
        </a>
      </div>
    </nav>

    <bulmaModal v-bind:title="'Login'" v-bind:buttons="['Login', 'Cancel']" v-on:click="manageModal($event)" >
      <h1>Welcome to Florida Tile SalesBuddy</h1>
      <p>You will be redirect to your corporate login platform</p>
    </bulmaModal>
  </div>

</template>

<script>

export default {
  name: 'bulmaLogin',
  components: {
  },
  computed: {
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
  },
  props: {
    id: String,
  },
  data() {
    return {
    }
  },
  mounted: function() {
  },
  async created() {
  },
  methods: {
    manageModal: async function(b) {
      console.log('manageModal', b);
      if (b==1 || b==-1) {
        this.test_validate();
      }  
      if (b==0) {
        await this.test_login();
      }
    },

    test_validate: async function() {
      console.log('test_validate');
      let x=await this.$store.dispatch('validate_login');
      console.log(x);
    },

    test_login: async function() {
      console.log('test_login');
      
      let email='ptorric@gnet.it';
      let myid_token='12345';
      let res=await this.$store.dispatch('login', { email: email, myid_token: myid_token } )
      if (res!=true && res!=false) {
        console.log('redirect!');
        console.log(res);
        window.location.href=res;
      }
    },
  }
}
</script>

<style scoped>
</style>

<template lang="html">
    <article class="message">
      <div class="message-body">
        <div class="content">
          <span v-for="riga in this.config.help" v-html="riga"></span>
        </div>
    </div>
    </article>
</template>

<script>

export default {
  name: 'bulmaHelp',
  components: {
  },
  computed: {
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
  },
  props: {
    id: String,
  },
  data() {
    return {
    }
  },
  mounted: function() {
  },
  async created() {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>

<template lang="html">
    <bulmaModal v-bind:buttons="['Save', 'Cancel']" v-on:click="manageModal($event)" v-bind:title="title">
      <div class="field">
        <label class="label">Name</label>
        <div class="control">
          <input class="input" type="text" placeholder="Leads" v-bind:value="safetabname" v-on:change="tabname=$event.target.value">
        </div>
      </div>
      <div class="field">
        <label class="label">Path</label>
        <div class="control">
          <input class="input" type="text" placeholder="/leads" v-bind:value="safetabpath" v-on:change="tabpath=$event.target.value">
        </div>
      </div>
      <div class="field" v-if="!isnew">
        <label class="label">Order</label>
        <div class="control">
          <input class="input" type="text" placeholder="1" v-bind:value="safetaborder" v-on:change="taborder=$event.target.value">
        </div>
      </div>
    </bulmaModal>
</template>

<script>
import bulmaModal from '../components/bulma_modal.vue'

export default {
  name: 'bulmaTabProps',
  components: {
    bulmaModal
  },
  computed: {
    title: function() {
      if (this.isnew) return "Create a new tab";
      return "Change tab";
    },
    safetabname: function() {
      if (this.tabname) return this.tabname;
      this.tabname = this.tab.name;
      return this.tabname;
    },
    safetabpath: function() {
      if (this.tabpath) return this.tabpath;
      this.tabpath = this.tab.path;
      return this.tabpath;
    },
    safetaborder: function() {
      if (this.taborder) return this.taborder;
      this.taborder = this.tab.index;
      return this.taborder;
    }
  },
  props: {
    tab: Object,
    isnew: Boolean,
  },
  data() {
    return {
      tabname:'',
      tabpath:'',
      taborder:'',
    }
  },
  mounted: function() {
  },
  async created() {

  },
  methods: {
    
    manageModal: function(b) {
      console.log('manageModal', b);
      if (b==1 || b==-1) this.$emit('cancel');      
      if (b==0) {
        console.log('change:', this.tabname);
        this.$emit('change', { index: this.tab.index, name: this.tabname, path: this.tabpath, order: this.taborder } );
      }
    }
  }
}
</script>

<style scoped>
</style>

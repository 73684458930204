<template lang="html">
  <div v-bind:id="id" >
    <bulmacontainerprops v-if="showModal" v-on:change="setContainerProps($event)" v-on:cancel="showModal=false" v-bind:cells="config.cells" v-bind:cellsno="config.cells.length"></bulmacontainerprops>
    <div class="box"  v-if="editmode" ><span class="icon" v-on:click="showModal=true"><i class="fas fa-pen"></i></span></div>
    <div class="columns" v-bind:id="id">
      <div class="column" v-for="c in config.cells" v-bind:key="c.name" v-bind:class="'is-'+c.width">
        <div class="">
          <div class="box"  v-if="editmode" ><span class="icon" v-on:click="showModal=true"><i class="fas fa-plus"></i></span></div>
          <component v-bind:is="c.type" v-bind:id="c.id"></component>
        </div>
      </div>
      <div class="column is-full" v-if="!config.cells">
        <div class="box"><span class="icon" v-if="editmode" v-on:click="showModal=true"><i class="fas fa-pen"></i></span></div>
      </div>
    </div>
  </div>
</template>

<script>
import bulmacontainerprops from '../components/bulma_containerprops.vue'

export default {
  name: 'bulmaContainer',
  components: {
    bulmacontainerprops,
  },
  computed: {
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
    editmode: function() {
      return this.$store.getters.getEditMode(this.id);
    },
    romode: function() {
      return this.$store.getters.getROMode(this.id);
    }
  },
  props: {
    id: String,
  },
  data() {
    return {
      showModal:false
    }
  },
  mounted: function() {
  },
  async created() {
  },
  methods: {
    saveConfig: function() {
      console.log('saveConfig');
    },
    gotoEditMode: function(flag) {
      var payload={ id: this.id, editmode: flag };
      this.$store.commit('SET_EDITMODE', payload);
    },
    setContainerProps: function( p ) {
      this.showModal=false;
      this.$emit('change', p.cols);
    }
  }
}
</script>

<style scoped>
</style>

<template lang="html">

  <div class="columns is-multiline" v-bind:id="id" >
    <div class="column" v-bind:class="{'is-half':config.dual,'is-full':!config.dual}" v-for="(field, index) in config.fields" v-bind:key="field.name">
      <bulmaField  v-bind:id="field.name" v-bind:data="field"></bulmaField>
    </div>
  </div>

</template>

<script>

export default {
  name: 'bulmaFieldSet',
  components: {},
  props: {
    id: String,
    recordid: String,
  },
  data: function () {
      return {
    }
  },
  watch: {
  },
  computed: {
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
  },
  mounted: function() {
  },
  filters: {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>

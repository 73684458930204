<template lang="html">

  <bulmaModal v-bind:buttons="['Save', 'Cancel']" v-on:click="manageModal($event)" v-bind:title="'Container Proprerties'">
    <div class="field">
      <label class="label">Number of columns</label>
      <div class="control">
        <input class="input" type="text" placeholder="3" v-bind:value="safecolsno" v-on:change="setCols($event.target.value)">
      </div>
    </div>
    <div class="field" v-for="c in safecols" v-bind:key="c.name">
      <label class="label">Column {{ c.name+1 }}</label>
      <div class="control">
        <input class="input" type="text" placeholder="2" v-bind:value="c.width" v-on:change="c.width=parseInt($event.target.value)">
      </div>
    </div>
    <div class="field">
      <label class="label">Total width: {{ total }}</label>
    </div>

  </bulmaModal>

</template>

<script>

export default {
  name: 'bulmaContainerProps',
  components: {
  },
  computed: {
    safecolsno: function() {
      if (this.colsno) return this.colsno;
      this.colsno = this.cellsno;
      return this.colsno;
    },
    safecols: function() {
      if (this.cols.length>0) return this.cols;
      this.cols = this.cells;
      return this.cols;
    },
    total: function() {
      let t=0;
      this.cols.forEach( c => {
        t+=c.width;
      });
      return t;
    },
  },
  props: {
    cells: Array,
    cellsno: Number
  },
  data() {
    return {
      colsno:0,
      cols:[]
    }
  },
  mounted: function() {
  },
  async created() {

  },
  methods: {

    setCols: function(c) {
      if (c>12) c=12;
      if (c<1) c=1;
      this.colsno=c;
      let w=parseInt(12/c);
      this.cols=[];
      for (let i=0; i<c; i++) this.cols.push({ name:i, width:w});
      let r=12-(w*c);
      let i=0;
      while(r>0) { this.cols[i++].width++; r--; }
    },
    manageModal: function(b) {
      console.log('manageModal', b);
      if (b==1 || b==-1) this.$emit('cancel');      
      if (b==0) {
        console.log('change:', this.tabname);
        this.$emit('change', { colsno: this.colsno, cols: this.cols } );
      }
    }
  }
}
</script>

<style scoped>
</style>

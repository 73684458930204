<template lang="html">

  <div class="timeline" v-bind:id="id" >
    <div v-for="event in events" v-bind:key="event.id" v-bind:class="{'timeline-item':event.type=='item','timeline-header':event.type=='header'}">

      <span v-if="event.type=='header'" class="tag is-primary">{{ event.subject }}</span>

      <div v-if="event.type=='item'" class="timeline-marker" v-bind:class="{'is-icon':event.icon}">
        <i class="fa" v-bind:class="'fa-'+event.icon"></i>
      </div>

      <div v-if="event.type=='item'" class="timeline-content">
        <p class="heading">{{ event.date }}</p>
        <p>{{ event.activity }}{{ event.subject?' - '+event.subject:'' }}</p>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'bulmaTimeline',
  components: {
  },
  computed: {
    config: function() {
      return '';
//      return this.$store.getters.getObject(this.id).data;
    },
  },
  props: {
    id: String,
    events: Array
  },
  data() {
    return {
    }
  },
  mounted: function() {
  },
  async created() {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>

<template lang="html">

  <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation" v-bind:id="id" >
    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        <img src="/images/logo/Florida-Tile-logo.png"/>
      </a>

      <a class="navbar-item largedevice" v-for="(tab, index) in config.tabs" v-bind:key="tab.order" v-bind:class="{ 'is-active': tab.name==config.active_tab }" v-on:click="tabClick(index)">
        <span class="icon" v-if="tab.icon"><i class="fas" v-bind:class="'fa-'+tab.icon"></i></span>
      </a>

      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false"  v-bind:class="{ 'is-active':burger_open }" v-on:click="burger_open=!burger_open">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" v-bind:class="{ 'is-active':burger_open }">
      <div class="navbar-start">
        <a class="navbar-item" v-for="(tab, index) in config.tabs" v-bind:key="tab.order" v-bind:class="{ 'is-active': tab.name==config.active_tab }" v-on:click="tabClick(index)">
          <span v-if="editmode">{{ index }}:</span>{{ $t(tab.name) }}
        </a>

        <!--
        <a class="navbar-item" v-on:click="newTab()" v-if="editmode">
          +
        </a>
        <a class="navbar-item" v-on:click="gotoEditMode(false); saveConfig();" v-if="editmode">
          <span class="icon"><i class="fas fa-check"></i></span> 
        </a>
        <a class="navbar-item" v-on:click="gotoEditMode(true)" v-if="!editmode">
          <span class="icon"><i class="fas fa-pen"></i></span> 
        </a>
        -->
      </div>
    </div>

    <bulmatabprops v-if="showModal" v-on:change="setTabName($event)" v-on:cancel="showModal=false" v-bind:isnew="isnew" v-bind:tab="tab"></bulmatabprops>

  </nav>

</template>

<script>
import bulmatabprops from '../components/bulma_tabprops.vue'

export default {
  name: 'bulmaTabBar',
  components: {
    bulmatabprops
  },
  props: {
    id: String,
    data: Object,
  },
  watch: { 
    process: function(newVal) {
      if (!newVal) newVal='/';
      else newVal='/'+newVal;
      let t=this.config.tabs.find( (t) => {
        return t.path==(newVal);
      })
      this.updateConfig("active_tab", t.name);
    },
  },
  computed: {
    process: function() {
      return this.$store.state.global_state_process;
    },
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
    editmode: function() {
      return this.$store.getters.getEditMode(this.id);
    },
    romode: function() {
      return this.$store.getters.getROMode(this.id);
    }
  },
  data() {
    return {
      showModal: false,
      isnew: false,
      tabindex: 0,
      tab:{
        name:'',
        path:'',
        index: -1
      },

      burger_open: false,
    }
  },
  methods: {
    saveConfig: function() {
      console.log('saveConfig');
    },
    gotoEditMode: function(flag) {
      var payload={ id: this.id, editmode: flag };
      this.$store.commit('SET_EDITMODE', payload);
    },
    updateConfig: function( name, val ) {
      var c=this.config;
      c[name]=val;
      var payload={ id:this.id, cfg:{ id:this.id, type:this.type, data:c} }      
      this.$store.commit('SET_CONFIG', payload);
    },
    setTabName: function(tab) {
      console.log('setTabName', tab);      
      this.showModal=false;
      if (this.isnew) {
        this.$emit('add', tab);
      } else {
        this.$emit('change', tab);
      }
    },
    newTab: function() {
      this.isnew=true;
      this.showModal=true;
      this.tab.name='';
      this.tab.path='';
      this.tab.index=-1;
    },
    tabClick: function(tabindex) {
      this.burger_open=false;
      var t=this.config.tabs[tabindex];
      if (!this.editmode) {
        this.updateConfig("active_tab", t.name);
        this.$router.push(t.path);
        this.$emit('click', t);
      } else {
        this.tab.index=tabindex;
        this.tab.name=t.name;
        this.tab.path=t.path;
        this.isnew=false;
        this.showModal=true;
      }
    },
  }
}
</script>

<style scoped>

</style>

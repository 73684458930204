<template lang="html">

  <div class="" v-bind:id="id" >

    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-4">{{ config.title }}</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button v-if="config.caninsert" class="button is-rounded"> <span class="icon" v-on:click="search()"><i class="fas fa-plus"></i></span> </button>
          <button class="button is-rounded"> <span class="icon" v-on:click="search()"><i class="fas fa-magnifying-glass"></i></span> </button>
          <button class="button is-rounded"> <span class="icon" v-on:click="loadRows()"><i class="fas fa-rotate-right"></i></span> </button>
        </div>
        <div class="level-item">
          <div class="select is-rounded" v-if="config.lists">
            <select>
              <option v-for="list in config.lists" v-bind:key="list.name">{{ $t(list.label) }}</option>
            </select>
          </div>

        </div>
      </div>
    </div>
    
    <bulmaTimeline v-bind:events="records"></bulmaTimeline>

    <nav class="level" v-if="$store.state.busy">
      <div class="level-item has-text-centered">
        <button class="button is-loading" >Loading</button>
      </div>
    </nav>
    <bulmaPagination v-if="!$store.state.busy" v-bind:maxPages="maxPages" v-bind:currentPage="currentPage" v-on:next="loadNextPage" v-on:previous="loadPreviousPage"></bulmaPagination>
  </div>

</template>

<script>

export default {
  name: 'bulmaActivities',
  components: {},
  props: {
    id: String,
  },
  data: function () {
      return {
        records: [],
        currentPage:1,
        nrecbypage:10,
    }
  },
  watch: {
  },
  computed: {
    maxPages: function() {
      let res=this.$store.getters.getRecordsList(this.id);
      if (res) {
        return Math.ceil(res.totalRecords/this.nrecbypage);
      }
      return 0;
    },
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
  },
  mounted: function() {
    if (this.config.hydration) {
      this.hydrate();
    } else {
      this.loadRows();
    }
  },
  filters: {
  },
  methods: {
    search: function() {
      alert('tbd sorry')
    },
    loadNextPage: async function() {
      this.currentPage++;
    },
    loadPreviousPage: async function() {
      this.currentPage--;
    },
    loadRows: async function() {
      this.records=[];

      let p=[];
      this.config.cols.forEach( f => {
        if (f.fieldname!='id') p.push(f.fieldname);
      });

      let filters=[];

      if (this.config.related) {
        let process=this.$store.state.global_state_process;
        let pconfig=this.$store.getters.getProcess(process);
        filters.push(
          {
            "propertyName": "associations."+pconfig.table.slice(0, pconfig.table.length-1),
            "operator": "EQ",
            "value": this.$store.getters.getRecordId
          }
        );
      }

      let res=await this.$store.dispatch('search_records', { id: this.id, object: this.config.table, filters: filters, properties:p});
      if (res.data) {
        this.makeRecords(res.data.results);
      }
    },
    // TODO: hydrate is for hubspot, currently broken :)
    hydrate: async function() {
      this.records=[];

      let records=this.$store.getters.getRecordAssoc(this.config.table);
      this.$store.commit('SET_RECORDS_DATA', { id:this.id, data:records});
      let p=[];
      this.config.cols.forEach( f => {
        if (f.fieldname!='id') p.push(f.fieldname);
      });

      let res=await this.$store.dispatch('hydrate_records', { id: this.id, object: this.config.table, properties:p});
      if (res.data) {
        this.makeRecords(res.data.results);
      }
    },
    makeRecords: function(results) {
      results.forEach( (r) => {
          let event={
            date: r.properties.hs_createdate,
            type:'item',
            activity:'',
            icon:''
          }
          switch (r.properties.hs_engagement_type) {
            case 'EMAIL':
              event.activity='Email';
              event.icon='envelope';
              break;
            case 'NOTE':
              event.activity='Note';
              event.icon='comment';
              break;
          }
          this.records.push(event);
        })
    },
    select: function(index) {
      //console.log('record select:', index);
      // TODO: fix configurable id
      let r=this.records[index];
      this.$store.commit( 'SET_RECORDS_ID', { id: this.id, data:r.Id } );
      this.$store.commit( 'SET_RECORDS_STATE', { id: this.id, state:'TOLOAD' } );
      this.$router.push(this.config.path.details+r.id);
      this.$emit('click', index);
    },
  }
}
</script>

<template lang="html">
  <div v-bind:id="id" style="width: 100%; height: 20em;">
    
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'bulmaGraph',
  components: {
  },
  computed: {
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
  },
  props: {
    id: String,
  },
  data() {
    return {
    }
  },
  mounted: function() {
    var myChart = echarts.init(document.getElementById(this.id));
    myChart.setOption(this.config);
  },
  async created() {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>

<template lang="html">

  <div class="" v-bind:id="id" >
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-4">{{ config.title }}</h1>


        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div class="select is-rounded" v-if="config.lists">
            <select>
              <option v-for="list in config.lists" v-bind:key="list.name">{{ $t(list.label) }}</option>
            </select>
          </div>

        </div>
      </div>
    </div>
    <table class="table">

      <thead>
        <tr>
          <th v-for="(col, index) in config.cols" v-bind:key="col.order" style="vertical-align:top"  v-on:click="$emit('filter',index)"  v-if="col.type!='hidden'">
            <span v-bind:style=" (wrapheader?'white-space: normal':'') " >{{col.label}}</span>

            <span v-if="col.filtered" class="icon"><i class="fas fa-filter"></i></span>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-on:click="$emit('row',rindex)" v-for="(record, rindex) in records" v-bind:key="record.id" >

          <td v-for="(col,index) in config.cols" v-bind:key="col.order" v-bind:style="'text-align:'+align(col.type)" v-if="col.type!='hidden'" >

            <a v-if="col.type=='link'" v-bind:href="$route.path+'/'+record[ col.linkname ]" v-on:click.prevent="select(rindex)">
              {{ filter( getFieldValue(record, col.name), col.type ) }}
            </a>

            <span v-if="col.type!='link'">
              <span v-if="col.type=='alert' && record[col.name]" class="icon"><i class="fas fa-bell"></i></span>
              {{ filter( getFieldValue(record, col.name), col.type ) }}
            </span>

          </td>

        </tr>
      </tbody>
    </table>
  </div>

</template>

<script>
/*
  cols
    label
    order
    name
    type
  records
    name
*/

import numeral from 'numeral'
import moment from 'moment'
import fakedata from '@/data.js'

export default {
  name: 'bulmaForm',
  components: {},
  props: {
    id: String,
    
    wrapheader: Boolean,
    wrapcell: Boolean,
  },
  data: function () {
      return {
        records: []
    }
  },
  watch: {
  },
  computed: {
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
  },
  mounted: function() {
    this.loadRows();
  },
  filters: {
  },
  methods: {
    loadRows: function() {
      this.records=[];
      fakedata.results.forEach( d => {
        let r={
          id:d.id,
          Firstname: d.properties.firstname,
          Lastname: d.properties.lastname,
          Email: d.properties.email,
        }
        this.records.push(r);
      });
    },
    filter: function(v, type) {
      switch(type) {
        case 'hidden':
          return;
        case 'date':
          if (v) {
            var n=new moment(v);
            return n.format('DD/MM/YYYY');
          }
          return v;
        case 'datetime':
          if (v) {
            var n=new moment(v);
            return n.format('DD/MM/YYYY');
          }
          return v;
        case 'decimal':
          if (v)
            return numeral(v).format('0,0');
          return v;
        case 'currency':
          if (v)
            return numeral(v).format('$ 0,0.00');
          return v;
        case 'alert':
            if (!v)
              return '';
            return '';
        case 'truncate':
          if (v)
            return v.substr(0,32);
      }
      return v;
    },
    getFieldValue: function(obj, path) {
      // eslint-disable-next-line no-redeclare
      for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
        if (obj)
          obj = obj[path[i]];
      }
      return obj;
    },
    align: function(type) {
      switch(type) {
        case 'currency':
        case 'decimal':
          return 'right';
      }
      return 'left';
    },
    select: function(index) {
      console.log('record select:', index);
      let r=this.records[index];
      this.$router.push(this.config.path.details+r.id);
      this.$emit('click', index);
    },
  }
}
</script>

<style scoped>
.bloccato {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
